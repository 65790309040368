import { render, staticRenderFns } from "./ProductCategoryListInternInland.vue?vue&type=template&id=39b2d00d&scoped=true&"
import script from "./ProductCategoryListInternInland.vue?vue&type=script&lang=js&"
export * from "./ProductCategoryListInternInland.vue?vue&type=script&lang=js&"
import style0 from "./ProductCategoryListInternInland.vue?vue&type=style&index=0&id=39b2d00d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b2d00d",
  null
  
)

export default component.exports