<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Edit Category
            </h2>
            <div>
              <button
                  class="btn btn-danger mr-2"
                  @click="handleDelete()"
              >
                Delete
              </button>
              <router-link
                  class="btn btn-primary"
                  :to="{ name: 'apps-product-category-list-food'}"
              >
                List Category
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class=""
              >
                <div
                  data-v-5cff0742=""
                  class="row"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__824__BV_label_"
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Name</label>
                      <div class="col"><input
                        id="h-first-name"
                        v-model="name"
                        type="text"
                        placeholder="Name"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__826__BV_label_"
                       class="col-md-2 col-form-label"
                     >Description</label>
                      <div class="col">
                        <input
                          v-model="description"
                          type="text"
                          placeholder="Description"
                          class="form-control"
                        ><!---->
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        class="col-md-2 col-form-label"
                    >Global</label>
                      <div class="col">
                        <select
                            id=""
                            v-model="is_global"
                            name=""
                            class="form-control"
                        >
                          <option value="1">
                            True
                          </option>
                          <option value="0">
                            False
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      role="group"
                      class="form-row form-group"
                    ><label
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Image</label>
                      <div class="col">
                        <label class="label-input-file">
                          <b>Choose File</b>
                          <input
                            id="file"
                            type="file"
                            @change="onFileChange( $event)"
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id=""
                       class="col-md-2 col-form-label"
                     >Active</label>
                      <div class="col">
                        <select v-model="status" class="form-control">
                          <option value="1">
                            True
                          </option>
                          <option value="0">
                            False
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="offset-md-4 col"
                  >
                    <button
                      data-v-5cff0742=""
                      type="button"
                      class="btn mr-1 btn-primary"
                      @click.prevent="submitEditProductCategory()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'

export default {

  name: 'ProductCategoryEdit',
  data() {
    return {
      name: '',
      description: '',
      file: '',
      status: '',
      is_global: 1,
    }
  },
  created() {
    this.getDetailProductCategory(this.$route.params.id).then(res => {
      console.log('RES VIEW getDetailProductCategory', res)
      this.name = res.data.data.name
      this.description = res.data.data.description
      this.status = res.data.data.status
      this.is_global = res.data.data.is_global ? 1 : 0
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapActions(['getDetailProductCategory', 'updateProductCategory','deleteGlobalCategory']),
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    submitEditProductCategory() {
      const data = {
        id: this.$route.params.id,
        name: this.name,
        description: this.description,
        is_global: !!this.is_global,
        status: parseInt(this.status),
        image_id: this.file,
        tags: [1],
      }
      this.updateProductCategory(data)
    },
    async handleDelete() {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Are you sure ?')) {
        await this.deleteGlobalCategory(this.$route.params.id);
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>

</style>
