<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Create Campaign
            </h2>
            <router-link
              class="btn btn-primary"
              :to="{ name: 'apps-campaign-list'}"
            >
              List Campaign
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class="col-12 row"
              >
                <div
                  data-v-5cff0742=""
                  class="row col-6"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                          for="h-first-name"
                          class="col-md-4 col-form-label"
                        >
                          Message</label>
                        <input
                          v-model="message"
                          type="text"
                          placeholder="Type Message"
                          class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>

                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                          class="col-md-4 col-form-label"
                        >Type</label>
                        <select
                          id=""
                          v-model="type_option"
                          class="form-control col-11"
                          name=""
                        >
                          <option value="1">
                            Product
                          </option>
                          <option value="2">
                            Global Category
                          </option>
                          <option value="3">
                            Area Category
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-5cff0742=""
                  class="row col-6"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                          id="__BVID__824__BV_label_"
                          for="h-first-name"
                          class="col-md-4 col-form-label"
                        >
                          Send At</label>
                        <input
                          id="h-first-name"
                          v-model="send_at"
                          type="datetime-local"
                          placeholder="Send At"
                          class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                    v-if="type_option === '1'"
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                          class="col-md-4 col-form-label"
                        >Product</label>
                        <select
                          id=""
                          v-model="product_id"
                          class="form-control col-11"
                          name=""
                        >
                          <option
                            v-for="item in listProduct"
                            :value="item"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="type_option === '2'"
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                          class="col-md-4 col-form-label"
                        >Global Category</label>
                        <select
                          id=""
                          v-model="global_category"
                          class="form-control col-11"
                          name=""
                        >
                          <option
                            v-for="item in listGlobal"
                            :value="item"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="type_option === '3'"
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                          class="col-md-4 col-form-label"
                        >Area Category</label>
                        <select
                          id=""
                          v-model="area_category"
                          class="form-control col-11"
                          name=""
                        >
                          <option
                            v-for="item in listArea"
                            :value="item"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-5cff0742=""
                  class="offset-md-4 col-12"
                >
                  <button
                    data-v-5cff0742=""
                    type="button"
                    class="btn m-5 btn-primary"
                    @click.prevent="submitAddCampaign()"
                  >
                    Submit
                  </button>
                </div>

              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {

  name: 'CampaignAdd',
  data() {
    return {
      message: '',
      type_option: '1',
      send_at: '',
      category_ids: '',
      product_ids: '',
      area_category: '',
      global_category: '',
      product_id: '',
      listArea: [],
      listGlobal: [],
      listProduct: [],
    }
  },
  created() {
    this.getListProductCategory({ skip: 0, limit: 10000 }).then(res => {
      this.listGlobal = res.data.data.items
      console.log('res getListProductCategory', res.data.data.items)
    })
    this.getListProductAreaCategory({ skip: 0, limit: 10000 }).then(res => {
      this.listArea = res.data.data.items
      console.log('res getListProductAreaCategory', res.data.data.items)
    })
    this.getListProductAdmin({ skip: 0, limit: 10000 }).then(res => {
      this.listProduct = res.data.data.items
      console.log('res getListProductAdmin', res.data.data.items)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListProductCategory']),
  },
  methods: {
    ...mapActions(['getListProductCategory', 'createProduct', 'getListProductAreaCategory', 'getListProductAdmin', 'createCampaign']),
    submitAddCampaign() {
      const datas = {
        message: this.message,
        send_at: this.send_at,
        type : this.type_option,
        data: {},
      }
      if (this.send_at === '') {
        alert('Please Choose Time !')
        return false
      }
      datas.send_at = moment(this.send_at).toISOString()
      if (this.type_option === '1') {
        datas.data.product_id = { id: this.product_id.id, name: this.product_id.name }
      }
      if (this.type_option === '2') {
        datas.data.global_category_id = { id: this.global_category.id, name: this.global_category.name }
      }
      if (this.type_option === '3') {
        datas.data.area_category_id = { id: this.area_category.id, name: this.area_category.name }
      }
      console.log('submitAddCampaign', datas)
      this.createCampaign(datas)
    },
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg1(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file1 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg2(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file2 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg3(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file3 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg4(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file4 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
  },
}
</script>

<style scoped>
#preview {
  /*align-items: center;*/
}

#preview img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.input-file {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}
.input-file-list {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}

.label-input-file {
  position: relative;
  width: 100%;
}

.label-input-file b {
  display: block;
  width: 100%;
  font-weight: normal;
  text-align: center;
  padding-top: 73px;
  cursor: pointer;
}

.label-input-file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

</style>
