<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Create Category Tag Inland & International
            </h2>
            <router-link
              class="btn btn-primary"
              :to="{ name: 'apps-product-category-list-intern-inland'}"
            >
              List Category
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class=""
              >
                <div
                  data-v-5cff0742=""
                  class="row"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__824__BV_label_"
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Name</label>
                      <div class="col"><input
                        id="h-first-name"
                        v-model="name"
                        type="text"
                        placeholder="Name"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__826__BV_label_"
                       class="col-md-2 col-form-label"
                     >Description</label>
                      <div class="col">
                        <input
                          v-model="description"
                          type="text"
                          placeholder="Description"
                          class="form-control"
                        ><!---->
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id=""
                       class="col-md-2 col-form-label"
                     >Tag</label>
                      <div class="col">
                        <select
                          id=""
                          v-model="tags"
                          name=""
                          class="form-control"
                        >
                          <option value="3">
                            International
                          </option>
                          <option value="4">
                            Inland
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        class="col-md-2 col-form-label"
                    >Global</label>
                      <div class="col">
                        <select
                            id=""
                            v-model="is_global"
                            name=""
                            class="form-control"
                        >
                          <option value="1">
                            True
                          </option>
                          <option value="0">
                            False
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        class="col-md-2 col-form-label"
                    >Level</label>
                      <div class="col">
                        <select
                            id=""
                            v-model="level"
                            name=""
                            class="form-control"
                        >
                          <option value="1">
                            1
                          </option>
                          <option value="2">
                            2
                          </option>
                          <option value="3">
                            3
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                      v-if="this.level != 1"
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        class="col-md-2 col-form-label"
                    >Category Parent</label>
                      <div class="col">
                        <select
                            id=""
                            v-model="parent_id"
                            name=""
                            class="form-control"
                        >
                          <option v-for="item in listCate" :value="item.id">
                            {{item.name}}
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      role="group"
                      class="form-row form-group"
                    ><label
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Image</label>
                      <div class="col">
                        <label class="label-input-file">
                          <b>Choose File</b>
                          <input
                            id="file"
                            type="file"
                            @change="onFileChange( $event)"
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="offset-md-4 col"
                  >
                    <button
                      data-v-5cff0742=""
                      type="button"
                      class="btn mr-1 btn-primary"
                      @click.prevent="submitAddProductCategory()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'

export default {

  name: 'ProductCategoryAddInternInland',
  data() {
    return {
      listCate: [],
      name: '',
      description: '',
      file: '',
      tags: 3,
      is_global: 1,
      level: 1,
      parent_id: '',
    }
  },
  created() {
    this.getListProductAreaCategory({ skip : 0,limit:1000 }).then(res => {
      console.log('RES CATEGORY', res)
      this.listCate = res.data.data.items.filter(el =>{
        if( el.tags != null || el.tags != undefined){
          return el.tags.includes(3) || el.tags.includes(4)
        }
      });
    })
  },
  methods: {
    ...mapActions(['createProductAreaCategory','getListProductAreaCategory']),
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    submitAddProductCategory() {
      if (this.file === '' || this.file == null){
        alert('Image is required !')
        return false
      }
      if((this.level == 2 || this.level == 3) && this.parent_id === ''){
        alert('parent is valid !')
        return false
      }
      const data = {
        name: this.name,
        description: this.description,
        tags: [parseInt(this.tags)],
        status: 1,
        image_id: this.file,
        is_global: !!this.is_global,
        level: parseInt(this.level),
        merchant: null,
        parent_id : this.level == 1 ? '' : this.parent_id,
      }
      this.createProductAreaCategory(data)
    },
  },
}
</script>

<style scoped>

</style>
