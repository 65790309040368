import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import moduleUser from './moduleUser'
import moduleProduct from './moduleProduct'
import moduleInvoice from './moduleInvoice'
import moduleOrder from './moduleOrder'
// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  getters: {
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    showNotification({ commit, dispatch }, data) {
      Vue.notify({
        title: data.title,
        type: data.type,
        duration: data.duration,
        text: data.text,
      })
    },
  },
  mutations: {
    SHOW_LOADING: (state, flag = false) => {
      state.isLoading = flag
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    moduleProduct,
    moduleUser,
    moduleInvoice,
    moduleOrder,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
