<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div
            data-v-5cb60a6f=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cb60a6f=""
              class="m-2"
            >
              <div
                data-v-5cb60a6f=""
                class="row"
              >
                <div class="col-3">
                  <router-link
                    class="btn btn-primary"
                    :to="{ name: 'apps-product-category-add-intern-inland'}"
                  >
                    Add
                  </router-link>
                </div>
              </div>
            </div>
            <div
              data-v-5cb60a6f=""
              class="position-relative table-responsive"
            >
              <table
                id="__BVID__1070"
                role="table"
                aria-busy="false"
                aria-colcount="7"
                class="table b-table"
              ><!---->
                <!---->
                <thead
                  role="rowgroup"
                  class=""
                ><!---->
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                    >
                      <div>#</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="3"
                      aria-sort="none"
                      class=""
                    >
                      <div>Name</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                    >
                      <div>Tag</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Is Global</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Status</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Created at</div>
                    </th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                  <tr
                    v-for="(item,index) in listParent"
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                  >
                    <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                    ><a
                      class="font-weight-bold"
                      target="_self"
                    > {{ index + 1 }} </a>
                    </td>
                    <td
                      :id="'ul1' + index"
                      aria-colindex="3"
                      role="cell"
                      class="td1"
                    >
                      <div
                        data-v-5cb60a6f=""
                        class="media"
                      >
                        <div
                          data-v-5cb60a6f=""
                          class="media-body"
                        ><span
                            style="padding-top: 10px"
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                        > {{ item.name }} </span></div>
                      </div>
                      <div class="dialogbox">
                        <div class="body">
                          <span class="tip tip-left"></span>
                          <div class="message">
                            <ul class="ul1">
                              <li v-for="level2 in getListLevel2(item.id)">
                                <router-link :to="{ name: 'apps-product-category-inter-inland-edit', params: { id: level2.id }}">
                                  -- {{ level2.name }}
                                </router-link>
                                <ul style="list-style: none">
                                  <li v-for="level3 in getListLevel3(level2.id)">
                                    <router-link :to="{ name: 'apps-product-category-inter-inland-edit', params: { id: level3.id }}" style="color: darkcyan">
                                      -- {{ level3.name }}
                                    </router-link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                      v-html="getTags(item.tags)"
                    />
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                      v-html="isGlobal(item.is_global)"
                    />
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                      v-html="isActive(item.status)"
                    />
                    <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                    >
                      {{ getDate(new Date(item.created_at )) }}
                    </td>
                    <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                    >
                      <router-link
                        :to="{ name: 'apps-product-category-inter-inland-edit', params: { id: item.id }}"
                        tag="button"
                        class="btn btn-warning"
                      >
                        Edit
                      </router-link>
                    </td>
<!--                    <td-->
<!--                      aria-colindex="6"-->
<!--                      role="cell"-->
<!--                      class=""-->
<!--                    >-->
<!--                      <button-->
<!--                        class="btn btn-danger"-->
<!--                        @click="handleDeleteArea(item.id)"-->
<!--                      >-->
<!--                        Delete-->
<!--                      </button>-->
<!--                    </td>-->
                  </tr>
                </tbody><!----></table>
            </div>
            <div
              data-v-5cb60a6f=""
              class="mx-2 mb-2"
            /><!----><!----></div>
          <div id="wrapper">
            <!-- start simple pagination -->
            <!--            <ul id="pagination">-->
            <!--              <li v-for="(page,index) in getNumberPage">-->
            <!--                <a @click="changePage(index)" :class="{ active: currentPage === (index + 1) }" href="#">{{ index + 1}}</a>-->
            <!--              </li>-->
            <!--            </ul>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'
import $ from 'jquery'

export default {
  name: 'ProductCategoryListInternInland',
  components: {

  },
  data() {
    return {
      listCate: [],
      listParent: [],
      currentPage: 1,
      perPage: 20,
      skip: 0,
      limit: 1000,
      totalItem: 1,
    }
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getListProductAreaCategory({ skip: this.skip, limit: this.limit }).then(res => {
      console.log('RES getListProductAreaCategory', res)
      this.$store.commit('SHOW_LOADING', false)
      this.listParent = res.data.data.items.filter(el => el.level == 1)
      this.listCate = res.data.data.items.filter(el => {
        if (el.tags != null || el.tags != undefined) {
          return el.tags.includes(3) || el.tags.includes(4)
        }
      })
      // this.totalItem = this.listParent.length
    }).catch(e=>{
      this.$store.commit('SHOW_LOADING', false)
    })
  },
  mounted() {

  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListProductCategory']),
    getNumberPage() {
      return Math.ceil(this.totalItem / this.perPage)
    },
  },
  methods: {
    ...mapActions(['getListProductAreaCategory', 'deleteAreaCategory']),
    isActive(flag) {
      if (flag) {
        return '<button class="btn btn-success">Hoạt động</button>'
      }
      return '<button class="btn btn-danger">Ngừng hoạt động</button>'
    },
    isGlobal(flag) {
      if (flag) {
        return '<button class="btn btn-success">True</button>'
      }
      return '<button class="btn btn-danger">False</button>'
    },
    getTags(tag) {
      // eslint-disable-next-line valid-typeof
      if (typeof tag === 'object') {
        if (tag.includes(1)) {
          return 'Food'
        } if (tag.includes(3)) {
          return 'International'
        }
        return 'Inland'
      }
      return ''
    },
    getDate(date) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    changePage(page) {
      this.currentPage = page + 1
      this.getListProductAreaCategory({ skip: (page * this.limit - page), limit: this.limit }).then(res => {
        this.listParent = res.data.data.items.filter(el => {
          if (el.tags != null || el.tags != undefined) {
            return el.tags.includes(3) || el.tags.includes(4)
          }
        })
      })
    },
    // eslint-disable-next-line consistent-return
    async handleDeleteArea(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Are you sure ?')) {
        await this.deleteAreaCategory(id)
        await this.getListProductAreaCategory({ skip: this.skip, limit: this.limit }).then(res => {
          console.log('RES getListProductAreaCategory', res)
          this.listParent = res.data.data.items.filter(el => {
            if (el.tags != null || el.tags != undefined) {
              return el.tags.includes(3) || el.tags.includes(4)
            }
          })
          // this.totalItem = res.data.data.total
        })
      } else {
        return false
      }
    },
    getListLevel2(id) {
      return this.listCate.filter(el => el.parent_id == id && el.level == 2)
    },
    getListLevel3(id) {
      return this.listCate.filter(el => el.parent_id == id && el.level == 3)
    },
  },
}
</script>

<style scoped>
.ul1{
  list-style: none;
  background: white;
  border: 1px solid lavender;
  padding: 10px;
  min-width: 330px;
}
.td1{
  display: flex;justify-content: start;
  align-items: center;
}
.media {
  min-width: 200px;
}
.tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
  border: 10px solid #ccc;
}
.tip-left {
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  left: -10px;
}
.dialogbox .body {
  position: relative;
  max-width: 300px;
  height: auto;
}
</style>
