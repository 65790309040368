// eslint-disable-next-line import/named
import { axiosInstance, axiosSendGrid } from '@/helpers/axiosInstance'
import router from '@/router'

// eslint-disable-next-line no-unused-vars
async function uploadFile(data, token) {
  console.log('data', data)
  let file1 = null
  let file2 = null
  let file3 = null
  let file4 = null
  const headers = {
    'Content-Type': 'application/json',
    'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
    // eslint-disable-next-line no-useless-concat
    Authorization: `Bearer ${token}`,
  }
  const formData = new FormData()
  formData.append('file', data.image_id)
  console.log('data', data)
  // eslint-disable-next-line camelcase
  const image_id = await axiosInstance.post('/api/v1/admin/files/upload', formData, { headers })
  if (data.image_1 !== '') {
    const formData2 = new FormData()
    formData2.append('file', data.image_1)
    file1 = await axiosInstance.post('/api/v1/admin/files/upload', formData2, { headers })
  }
  if (data.image_2 !== '') {
    const formData3 = new FormData()
    formData3.append('file', data.image_2)
    file2 = await axiosInstance.post('/api/v1/admin/files/upload', formData3, { headers })
  }
  if (data.image_3 !== '') {
    const formData4 = new FormData()
    formData4.append('file', data.image_3)
    file3 = await axiosInstance.post('/api/v1/admin/files/upload', formData4, { headers })
  }
  if (data.image_4 !== '') {
    const formData5 = new FormData()
    formData5.append('file', data.image_4)
    file4 = await axiosInstance.post('/api/v1/admin/files/upload', formData5, { headers })
  }
  return {
    image_id,
    file1,
    file2,
    file3,
    file4,
  }
}

export default {
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListProductCategory({ commit, state }, { skip, limit }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/global-categories?skip=${skip}&limit=${limit}`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async createProductCategory({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('createProductCategory', JSON.stringify(data))

      if (data.image_id === '' || data.image_id == null) {
        alert('File is required !')
        commit('SHOW_LOADING', false)
        return false
      }
      const formData = new FormData()
      formData.append('file', data.image_id)
      // eslint-disable-next-line camelcase
      const image = await axiosInstance.post('/api/v1/admin/files/upload', formData, { headers })
      // eslint-disable-next-line no-param-reassign
      data.image_id = image.data.data.id
      await axiosInstance.post('/api/v1/admin/global-categories', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Category Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product-category/list/food')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async getDetailProductCategory({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/global-categories/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateProductCategory({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('updateProductCategory', data)

      if (data.image_id !== '') {
        const formData = new FormData()
        formData.append('file', data.image_id)
        // eslint-disable-next-line camelcase
        const image = await axiosInstance.post('/api/v1/admin/files/upload', formData, { headers })
        // eslint-disable-next-line no-param-reassign
        data.image_id = image.data.data.id
      }

      await axiosInstance.put(`/api/v1/admin/global-categories/${data.id}`, data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response updateProductCategory', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update Category Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product-category/list/food')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListProduct({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      const merchant = JSON.parse(localStorage.getItem('MERCHANT'))
      await axiosInstance.get(`/api/v1/products?merchant_id=${merchant.merchant_id}&limit=50`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          commit('SET_LIST_PRODUCT', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListAttributeByProduct({ commit, state }, id) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      await axiosInstance.get(`/api/v1/products/${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          console.log('response getListAttributeByProduct', response)
          // If request is good...
          commit('SET_LIST_ATTRIBUTE_BY_PRODUCT', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async createProduct({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('createProductCategory', data)
      await uploadFile(data, state.accessToken).then(async res => {
        console.log('RES', res)
        const arrImages = []
        if (res.file1 != null) arrImages.push(res.file1.data.data.id)
        if (res.file2 != null) arrImages.push(res.file2.data.data.id)
        if (res.file3 != null) arrImages.push(res.file3.data.data.id)
        if (res.file4 != null) arrImages.push(res.file4.data.data.id)
        console.log(arrImages)
        // eslint-disable-next-line camelcase,no-param-reassign
        data.image_id = res.image_id.data.data.id
        // eslint-disable-next-line no-param-reassign
        data.image_ids = arrImages
        console.log(JSON.stringify(data))
        await axiosInstance.post('/api/v1/products', data, { headers })
        // eslint-disable-next-line consistent-return
          .then(async response => {
            // If request is good...
            console.log('response', response)
            await dispatch('showNotification', {
              title: 'Success !', type: 'success', duration: 5000, text: 'Creat Product Success !',
            })
            await commit('SHOW_LOADING', false)
            setTimeout(() => {
              router.push('/apps/product/list')
            }, 1000)
          })
          .catch(error => {
            console.log(error)
            commit('SHOW_LOADING', false)
            dispatch('showNotification', {
              title: 'Error',
              type: 'error',
              duration: 7000,
              text: error.response.data.message,
            })
            return false
          })
      }).catch(e => {
        console.log('e', e)
      })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async createAttribute({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('DATA createAttribute', JSON.stringify(data))
      await axiosInstance.post(`/api/v1/products/${data.product_id}/attributes`, data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat attribute Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  // eslint-disable-next-line no-unused-vars
  async sendMailInvoice({ commit, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'key-mail': 'OELSL4Urj4',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${process.env.VUE_APP_KEY_SEND_GRID}`,
      }
      const obj = {
        personalizations: [{ to: [{ email: data.toMail }] }],
        from: { email: data.from, name: 'EPIS' },
        subject: data.subject,
        content: [{ type: 'text/html', value: `<a clicktracking='off' href='https://merchant-frontend.pages.dev/apps/invoice/preview-payment/${data.invoiceId}'>Click Here To Continue Pay Now !</a>` }],
      }
      await axiosSendGrid.post('/mail', obj, { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          console.log('RES', response)
          commit('SHOW_LOADING', false)
          alert('Check Email to continue pay now !')
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateTimeOpen({ commit, dispatch, state }, obj) {
    commit('SHOW_LOADING', true)
    try {
      console.log('obj updateTimeOpen', obj)
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      await axiosInstance.post('/api/v1/auth/open', obj, { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          commit('SHOW_LOADING', false)
          // If request is good...
          console.log('response updateTimeOpen', response)
          // commit('SET_LIST_CATEGORY', response.data)
          dispatch('showNotification', {
            title: 'Success',
            type: 'success',
            duration: 7000,
            text: 'Thành công !',
          })
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'Có lỗi xảy ra, vui lòng thử lại !',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'Có lỗi xảy ra, vui lòng thử lại !',
      })
      return false
    }
  },
  async getListMerchant({ commit, state }, { skip, limit }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('state.accessToken', headers)
      return await axiosInstance.get(`/api/v1/admin/merchants?skip=${skip}&limit=${limit}`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getDetailMerchant({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/merchants/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateMerchant({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('updateMerchant', JSON.stringify(data))
      await axiosInstance.put(`/api/v1/admin/merchants/${data.id}`, data, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response updateMerchant', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update merchant Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/merchant/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async getListProductAreaCategory({ commit, state }, { skip, limit }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/area-categories?skip=${skip}&limit=${limit}`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListProductAdmin({ commit, state }, { skip, limit }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/products?skip=${skip}&limit=${limit}`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async createProductAreaCategory({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('createProductCategory', data)

      const formData = new FormData()
      formData.append('file', data.image_id)
      // eslint-disable-next-line camelcase
      const image = await axiosInstance.post('/api/v1/admin/files/upload', formData, { headers })
      // eslint-disable-next-line no-param-reassign
      data.image_id = image.data.data.id
      await axiosInstance.post('/api/v1/admin/area-categories', data, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Category Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product-category/list/intern-inland')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async getDetailProductAreaCategory({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/area-categories/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateProductAreaCategory({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('updateProductAreaCategory', JSON.stringify(data))

      if (data.image_id !== '') {
        const formData = new FormData()
        formData.append('file', data.image_id)
        // eslint-disable-next-line camelcase
        const image = await axiosInstance.post('/api/v1/admin/files/upload', formData, { headers })
        // eslint-disable-next-line no-param-reassign
        data.image_id = image.data.data.id
      }

      await axiosInstance.put(`/api/v1/admin/area-categories/${data.id}`, data, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response updateProductAreaCategory', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update Category Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product-category/list/intern-inland')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async createCampaign({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      console.log('DATA createCampaign', JSON.stringify(data))
      await axiosInstance.post('/api/v1/admin/campaigns', data, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat attribute Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/campaign/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async getListCampaign({ commit, state }, { skip, limit }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/campaigns?skip=${skip}&limit=${limit}`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getDetailProduct({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.get(`/api/v1/admin/products/${data}`, { headers }).then(res => res.data.data.name)
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async deleteGlobalCategory({ commit, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.delete(`/api/v1/admin/global-categories/${id}`, { headers }).then(res => {
        commit('SHOW_LOADING', false)
        setTimeout(() => {
          router.push('/apps/product-category/list/food')
        }, 1000)
      }).catch(() => {
        commit('SHOW_LOADING', false)
      })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async deleteAreaCategory({ commit, state }, id) {
    await commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      return await axiosInstance.delete(`/api/v1/admin/area-categories/${id}`, { headers }).then(res => {
        commit('SHOW_LOADING', false)
        setTimeout(() => {
          router.push('/apps/product-category/list/intern-inland')
        }, 1000)
      }).catch(() => {
        commit('SHOW_LOADING', false)
      })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async deleteCampaign({ commit, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      commit('SHOW_LOADING', false)
      return await axiosInstance.delete(`/api/v1/admin/campaigns/${id}`, { headers })
    } catch (error) {
      console.log(error)
      return false
    }
  },
}
