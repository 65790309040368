<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div
            data-v-5cb60a6f=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cb60a6f=""
              class="m-2"
            >
              <div
                data-v-5cb60a6f=""
                class="row"
              >
                <div class="col-2">
                  <h4>List Campaign</h4>
                </div>
                <div class="col-3">
                  <router-link
                    class="btn btn-primary"
                    :to="{ name: 'apps-campaign-create'}"
                  >
                    Add
                  </router-link>
                </div>
              </div>
            </div>
            <div
              data-v-5cb60a6f=""
              class="position-relative table-responsive"
            >
              <table
                id="__BVID__1070"
                role="table"
                aria-busy="false"
                aria-colcount="7"
                class="table b-table"
              ><!---->
                <!---->
                <thead
                  role="rowgroup"
                  class=""
                ><!---->
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                    >
                      <div>#</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="3"
                      aria-sort="none"
                      class=""
                    >
                      <div>Message</div>
                    </th>
                    <th
                        role="columnheader"
                        scope="col"
                        tabindex="0"
                        aria-colindex="6"
                        aria-sort="none"
                        class=""
                    >
                      <div>Product</div>
                    </th>
                    <th
                        role="columnheader"
                        scope="col"
                        tabindex="0"
                        aria-colindex="6"
                        aria-sort="none"
                        class=""
                    >
                      <div>Global Category</div>
                    </th>
                    <th
                        role="columnheader"
                        scope="col"
                        tabindex="0"
                        aria-colindex="6"
                        aria-sort="none"
                        class=""
                    >
                      <div>Area Category</div>
                    </th>
                    <th
                        role="columnheader"
                        scope="col"
                        tabindex="0"
                        aria-colindex="6"
                        aria-sort="none"
                        class=""
                    >
                      <div>Send at</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Created at</div>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                  <tr
                    v-for="(item,index) in listCampaign"
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                  >
                    <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                    ><a
                      data-v-5cb60a6f=""
                      href="/apps/invoice/preview/5036"
                      class="font-weight-bold"
                      target="_self"
                    > {{ index + 1 }} </a></td>
                    <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                    >
                      <div
                        data-v-5cb60a6f=""
                        class="media"
                      >
                        <div
                          data-v-5cb60a6f=""
                          class="media-body"
                        ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                        > {{ item.message }} </span></div>
                      </div>
                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                      v-html="getProductDetail(item)"
                    >
                    </td>
                    <td
                        aria-colindex="4"
                        role="cell"
                        class=""
                        v-html="getGlobalCategoryDetail(item)"
                    >
                    </td>
                    <td
                        aria-colindex="4"
                        role="cell"
                        class=""
                        v-html="getAreaCategoryDetail(item)"
                    >
                    </td>
                    <td
                        aria-colindex="4"
                        role="cell"
                        class=""
                    >
                      {{ getDate(new Date(item.send_at )) }}
                    </td>
                    <td
                        aria-colindex="4"
                        role="cell"
                        class=""
                    >
                      {{ getDate(new Date(item.created_at )) }}
                    </td>
                    <td
                        aria-colindex="6"
                        role="cell"
                        class=""
                    >
                      <router-link
                          :to="{ name: 'apps-product-category-edit', params: { id: item.id }}"
                          tag="button"
                          class="btn btn-warning"
                      >
                        Edit
                      </router-link>
                    </td>
                    <td
                        aria-colindex="6"
                        role="cell"
                        class=""
                    >
                      <button
                          class="btn btn-danger"
                          @click="handleDelete(item.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody><!----></table>
            </div>
            <div
              data-v-5cb60a6f=""
              class="mx-2 mb-2"
            /><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'CampaignList',
  components: {},
  data() {
    return {
      listCampaign: []
    }
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getListCampaign({ skip: 0 , limit: 1000 }).then(res => {
      console.log('RES getListCampaign', res)
      this.$store.commit('SHOW_LOADING', false)
      this.listCampaign = res.data.data
      // this.totalItem = res.data.data.total
    }).catch(e=>{
      this.$store.commit('SHOW_LOADING', false)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListProduct']),
  },
  methods: {
    ...mapActions(['getListProduct','getListCampaign','getDetailProduct','deleteCampaign']),
    getDate(date) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    isActive(flag) {
      if (flag) {
        return '<button class="btn btn-success">Hoạt động</button>'
      }
      return '<button class="btn btn-danger">Ngừng hoạt động</button>'
    },
    // eslint-disable-next-line consistent-return
    async handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Are you sure ?')) {
        await this.deleteCampaign(id);
        await this.getListCampaign({ skip: 0 , limit: 1000 }).then(res => {
          console.log('RES getListCampaign', res)
          this.listCampaign = res.data.data
          // this.totalItem = res.data.data.total
        })
      } else {
        return false
      }
    },
    getGlobalCategoryDetail(el){
      if (el.data.global_category_id != null || el.data.global_category_id != undefined){
        return el.data.global_category_id.name
      }else{
        return '<span class="badge badge-danger">NOT SET</span>'
      }
    },
    getAreaCategoryDetail(el){
      if (el.data.area_category_id != null || el.data.area_category_id != undefined){
        return el.data.area_category_id.name
      }else{
        return '<span class="badge badge-danger">NOT SET</span>'
      }
    },
    getProductDetail(el){
      if (el.data.product_id != null || el.data.product_id != undefined){
        return el.data.product_id.name
      }else{
        return '<span class="badge badge-danger">NOT SET</span>'
      }
    },
  },
}
</script>

<style scoped>

</style>
