import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_SERVICE,
})
const axiosInstanceClient = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_CLIENT_API,
})
const axiosSendGrid = axios.create({
  baseURL: 'https://client-monmi.myepis.cloud',
})
axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 4012) {
    console.log('axiosInstance unauthorized !')
    alert('unauthorized , please login to request !')
   //  localStorage.removeItem('MERCHANT')
   //  localStorage.removeItem('ACCESS_TOKEN')
   // // logout
   //  // Remove userData from localStorage
   //  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
   //  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
   //  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
   //
   //  // Remove userData from localStorage
   //  localStorage.removeItem('userData')
   //
   //  // Reset ability
   //  this.$ability.update(initialAbility)
   //
   //  // Redirect to login page
   //  this.$router.push({ name: 'auth-login' })
  }
  return error;
});
export {
  // eslint-disable-next-line import/prefer-default-export
  axiosInstance, axiosInstanceClient,axiosSendGrid
}
