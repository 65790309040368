<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Edit Merchant
            </h2>
            <router-link
              class="btn btn-primary"
              :to="{ name: 'apps-merchant-list'}"
            >
              List Merchant
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class=""
              >
                <div
                  data-v-5cff0742=""
                  class="row"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__824__BV_label_"
                       for="h-first-name"
                       class="col-md-2 col-form-label"
                     >
                       Name</label>
                      <div class="col"><input
                        id="h-first-name"
                        v-model="name"
                        type="text"
                        placeholder="Name"
                        class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        for="h-first-name"
                        class="col-md-2 col-form-label"
                    >
                      Email</label>
                      <div class="col"><input
                          id=""
                          v-model="email"
                          type="text"
                          placeholder="Name"
                          class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        for="h-first-name"
                        class="col-md-2 col-form-label"
                    >
                      Phone</label>
                      <div class="col"><input
                          id=""
                          v-model="phone"
                          type="text"
                          placeholder="Phone"
                          class="form-control"
                      ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id="__BVID__826__BV_label_"
                       class="col-md-2 col-form-label"
                     >Description</label>
                      <div class="col">
                        <input
                          v-model="description"
                          type="text"
                          placeholder="Description"
                          class="form-control"
                        ><!---->
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id=""
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    ><label
                       id=""
                       class="col-md-2 col-form-label"
                     >Active</label>
                      <div class="col">
                        <select v-model="status" class="form-control">
                          <option value="1">
                            Active
                          </option>
                          <option value="2">
                            InActive
                          </option>
                          <option value="3">
                            Lock
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    ><label
                        id=""
                        class="col-md-2 col-form-label"
                    >Verified</label>
                      <div class="col">
                        <select v-model="is_verified" class="form-control">
                          <option value="1">
                            NON
                          </option>
                          <option value="2">
                            VERIFIED
                          </option>
                        </select>
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="offset-md-4 col"
                  >
                    <button
                      data-v-5cff0742=""
                      type="button"
                      class="btn mr-1 btn-primary"
                      @click.prevent="submitEditMerchant()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'

export default {

  name: 'MerchantEdit',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      description: '',
      file: '',
      status: '',
      is_verified: 1,
      location:{}
    }
  },
  created() {
    this.getDetailMerchant(this.$route.params.id).then(res => {
      console.log('RES VIEW getDetailMerchant', res)
      this.name = res.data.data.name
      this.email = res.data.data.email
      this.phone = res.data.data.phone
      this.description = res.data.data.description
      this.status = res.data.data.status
      this.location = res.data.data.location
      this.is_verified = res.data.data.is_verified
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapActions(['getDetailProductCategory', 'updateProductCategory', 'getDetailMerchant','updateMerchant']),
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    submitEditMerchant() {
      const data = {
        id: this.$route.params.id,
        name: this.name,
        description: this.description,
        email: this.email,
        phone: this.phone,
        status: parseInt(this.status),
        location: this.location,
        is_verified: parseInt(this.is_verified)
      }
      this.updateMerchant(data)
    },
  },
}
</script>

<style scoped>

</style>
