<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div
            data-v-5cb60a6f=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cb60a6f=""
              class="m-2"
            >
              <div
                data-v-5cb60a6f=""
                class="row"
              >
                <div class="col-3">
                  <!--                  <router-link-->
                  <!--                    class="btn btn-primary"-->
                  <!--                    :to="{ name: 'apps-product-category-add'}"-->
                  <!--                  >-->
                  <!--                    Add-->
                  <!--                  </router-link>-->
                </div>
              </div>
            </div>
            <div
              data-v-5cb60a6f=""
              class="position-relative table-responsive"
            >
              <table
                id="__BVID__1070"
                role="table"
                aria-busy="false"
                aria-colcount="7"
                class="table b-table"
              ><!---->
                <!---->
                <thead
                  role="rowgroup"
                  class=""
                ><!---->
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                    >
                      <div>#</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="3"
                      aria-sort="none"
                      class=""
                    >
                      <div>Name</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                    >
                      <div>Email</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                    >
                      <div>Phone</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Status</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Created at</div>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                  <tr
                    v-for="(item,index) in listMerchant"
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                  >
                    <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                    ><a
                      data-v-5cb60a6f=""
                      href="/apps/invoice/preview/5036"
                      class="font-weight-bold"
                      target="_self"
                    > {{ index + 1 }} </a></td>
                    <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                    >
                      <div
                        data-v-5cb60a6f=""
                        class="media"
                      >
                        <div
                          data-v-5cb60a6f=""
                          class="media-body"
                        ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                        > {{ item.name }} </span></div>
                      </div>
                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                    >
                      {{ item.email }}
                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                    >
                      {{ item.phone }}
                    </td>
                    <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                      v-html="isActive(item.status)"
                    />
                    <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                    >
                      {{ getDate(new Date(item.created_at )) }}
                    </td>
                    <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                    >
                      <router-link
                        :to="{ name: 'apps-merchant-edit', params: { id: item.id }}"
                        tag="button"
                        class="btn btn-warning"
                      >
                        Edit
                      </router-link>
                    </td>
                  </tr>
                </tbody><!----></table>
            </div>
            <div
              data-v-5cb60a6f=""
              class="mx-2 mb-2"
            />
          </div>
          <div id="wrapper">
            <!-- start simple pagination -->
            <ul id="pagination">
              <li v-for="(page,index) in getNumberPage">
                <a
                  :class="{ active: currentPage === (index + 1) }"
                  href="#"
                  @click="changePage(index)"
                >{{ index + 1 }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex/dist/vuex.esm.browser'
import { mapState } from 'vuex'

export default {
  name: 'MerchantList',
  components: {

  },
  data() {
    return {
      listMerchant: [],
      currentPage: 1,
      perPage: 20,
      skip: 0,
      limit: 20,
      totalItem: 1,
    }
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getListMerchant({ skip: this.skip, limit: this.limit }).then(res => {
      console.log('RES getListMerchant', res)
      this.listMerchant = res.data.data.items
      this.totalItem = res.data.data.total
      this.$store.commit('SHOW_LOADING', false)
    }).catch(e=>{
      this.$store.commit('SHOW_LOADING', false)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState(['isLoading']),
    // eslint-disable-next-line no-undef
    ...mapMutations(['SHOW_LOADING']),
    getNumberPage() {
      return Math.ceil(this.totalItem / this.perPage)
    },
  },
  methods: {
    ...mapActions(['getListMerchant']),
    isActive(flag) {
      if (flag == 1) {
        return '<button class="btn btn-success">Active</button>'
      }
      if (flag == 2) {
        return '<button class="btn btn-warning">InActive</button>'
      }
      return '<button class="btn btn-danger">Lock</button>'
    },
    getDate(date) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    changePage(page) {
      this.currentPage = page + 1
      this.getListMerchant({ skip: (page * this.limit - page), limit: this.limit }).then(res => {
        console.log('RES getListMerchant', res)
        this.listMerchant = res.data.data.items
        this.totalItem = res.data.data.total
      })
    },
  },
}
</script>

<style scoped>

</style>
