import { axiosInstance } from '@/helpers/axiosInstance'
import router from '@/router'

async function getLongLatByMerchant(address) {
  // console.log('merchant in getLongLatByMerchant', address)
  // console.log('headers in getLongLatByMerchant', headers)
  const headers = {
    'Content-Type': 'application/json',
    'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
  }
  try {
    const obj = {
      address: {
        province_id: null,
        province_name: null,
        district_id: null,
        district_name: null,
        address,
        ward_id: null,
        ward_name: null,
        long: null,
        lat: null,
      },
    }
    return await axiosInstance.post('/api/v1/locations/geocode', obj, { headers })
      .then(async res => {
        if (res.data.status_code == 200) {
          // console.log('res.data.data', res.data.data)
          return res.data.data
        }
        return false
      })
      .catch(er => {
        console.log('res getLongLatByMerchant catch', er)
        return false
      })
  } catch (error) {
    return false
  }
}
export default {
  // eslint-disable-next-line consistent-return
  async register({ commit, dispatch }, data) {
    commit('SHOW_LOADING', true)
    const longLatFromAdressUser = await getLongLatByMerchant(data.location.address)
    try {
      const obj = {
        email: data.email,
        password: data.password,
        name: data.name,
        description: data.description,
        phone: data.phone,
        location: {
          province_id: data.location.province_id,
          province_name: data.location.province_name,
          district_id: data.location.district_id,
          district_name: data.location.district_name,
          address: data.location.address,
          // eslint-disable-next-line radix
          ward_id: data.location.ward_id,
          ward_name: data.location.ward_name,
          lat: longLatFromAdressUser.lat,
          long: longLatFromAdressUser.long,
        },
        tags: [1],
        image_id: null,
        status: 1,
        code: 1,
        image: {

        },
        deliveries: [

        ],
      }
      console.log('DATTA', JSON.stringify(obj))
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
      }
      await axiosInstance.post('/api/v1/auth/register', obj, {
        headers,
      }).then(async () => {
        await dispatch('showNotification', {
          title: 'Success !', type: 'success', duration: 5000, text: 'Register Success !',
        })
        await commit('SHOW_LOADING', false)
        setTimeout(() => {
          router.push('/login')
        }, 1000)
      })
        .catch(error => {
          console.log('error catch')
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          commit('SHOW_LOADING', false)
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line no-unused-vars,consistent-return
  async login({ commit, dispatch }, data) {
    commit('SHOW_LOADING', true)
    try {
      const obj = {
        phone: data.phone,
        otp: data.otp,
      }
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
      }
      return await axiosInstance.post('/api/v1/admin/auth/login', obj, {
        headers,
      })
        .then(async res => {
          localStorage.setItem('ACCESS_TOKEN', res.data.data.token)
          await commit('SHOW_LOADING', false)
          return res
        })
        .catch(error => {
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          commit('SHOW_LOADING', false)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async sendOtp({ commit, dispatch }, data) {
    commit('SHOW_LOADING', true)
    try {
      const obj = {
        phone_number: data.phone,
        is_admin: true,
      }
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
      }
      return await axiosInstance.post('/api/v1/admin/auth/send', obj, { headers }).then(()=>{
        commit('SHOW_LOADING', false)
      }).catch(() =>{
        commit('SHOW_LOADING', false)
      })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async checkLogin({ commit, dispatch }) {
    try {
      const userLocal = localStorage.getItem('i4sign_in')
      const userVps = localStorage.getItem('USER_INFO_VPS')
      const accessToken = localStorage.getItem('ACCESS_TOKEN')

      const data = { userLocal, userVps, accessToken }

      if (!isEmptyObject(userLocal)) {
        commit('SET_LOGIN_INFO', data)
        return true
      }
      return false
    } catch (error) {
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getProvince({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      await axiosInstance.get('/api/v1/locations/provinces', { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          console.log('res getProvince', response)
          commit('SET_LIST_PROVINCE', response)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getDistrict({ commit, state }, id) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      await axiosInstance.get(`/api/v1/locations/districts?province_id=${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          console.log('res getDistrict', response)
          commit('SET_LIST_DISTRICT', response)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getWard({ commit, state }, id) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${state.accessToken}`,
      }
      await axiosInstance.get(`/api/v1/locations/wards?district_id=${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          console.log('res getWard', response)
          commit('SET_LIST_WARD', response)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getMe({ commit, state }, {}) {
    console.log('state', state)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get('/api/v1/auth/me', { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          console.log('res getMe', response)
          return response
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getLocationSearch({ commit, state }, str) {
    try {
      console.log('str', str)
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
      }
      return await axiosInstance.get(`/api/v1/locations/search?search=${str}`, { headers })
    } catch (error) {
      return false
    }
  },
}
