<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to MonMi! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <b-alert
            variant="primary"
            show
          />

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleLogin"
            >
              <!-- email -->
              <b-form-group

                label="Phone"
                label-for="login-email"
              >
                <validation-provider
                    class="d-flex"
                  #default="{ errors }"
                  name="Phone"
                  type="number"
                  vid="phone"
                  rules="required"
                >
                  <select v-model="firstPhone" name="" id="" class="form-control" style="width: 30%">
                    <option value="84">+84</option>
                    <option value="61">+61</option>
                  </select>
                  <b-form-input
                    id="login-email"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="84888820020"
                  />
                  <small style="position: absolute;bottom: 45px;" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group v-if="isSendOtp">
                <div class="d-flex justify-content-between">
                  <label>OTP</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="OTP"
                  vid="otp"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="otp"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="Enter OTP"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group v-if="isSendOtp">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  style="margin-top: 30px"
                v-if="isSendOtp"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
              <b-button
                  style="margin-top: 30px"
                v-if="!isSendOtp"
                type="submit"
                variant="primary"
                :disabled="invalid"
                block
              >
                Send OTP
              </b-button>
            </b-form>
          </validation-observer>

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <b-link :to="{name:'auth-register'}">-->
          <!--              <span>&nbsp;Create an account</span>-->
          <!--            </b-link>-->
          <!--          </b-card-text>-->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      phone: '',
      otp: '',
      firstPhone: '84',
      isSendOtp: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions(['login', 'sendOtp']),
    handleSendOTPLogin() {

    },
    handleLogin() {
      if (!this.isSendOtp){
        this.sendOtp({ phone: this.firstPhone + this.phone }).then(res => {
          console.log('send OTP res', res)
          this.isSendOtp = true
        }).catch(e => {
          console.log(e)
        })
      } else {
        const data = {
          phone: this.firstPhone + this.phone,
          otp: this.otp,
        }
        this.login(data).then(response => {
          console.log('RES LOGIN', response.data.data.token)
          const userData = {
            email: this.userEmail,
            role: 'admin',
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
          }
          console.log('response.data.data.token', response.data.data.token)
          useJwt.setToken(response.data.data.token)
          useJwt.setRefreshToken(response.data.data.token)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          this.$router.push(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${this.userEmail}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                  },
                })
              })
        }).catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
      }

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
